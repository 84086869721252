const Projects = [
    {
        "id": 14,
        "title": "Build-A-Story",
        "imgSrc": "https://lh3.googleusercontent.com/pw/AM-JKLWald7Va-zgO8A2ka1GtpSXLp3p6WD6sz7VCP9awwDBYzJzkXCBxM1HZaSZBX8Cijsd9mdvi5uqOycKYm8BW_p25pkgUBFWh5LndHGUSL70VWEnigp_VX-ZxrN_YRSD6b4m55ok2xOoS-hZRBzyZMCJTQ=w2115-h1190-no",
        "pretext": "Build-A-Story is an application that uses GPT-3 to generate stories for children and GCP Text-To-Speech WaveNet voices to read them out.",
        "timespan": "1 day",
        "date": "2022-02-06",
        "teamSize": 0,
        "links": "{\"git\": \"https://github.com/arj119/podcast-ai\"}",
        "technologies": "GPT-3, GCP, Flask, Ionic, React",
        "keywords": "NLP, Transformers, Text to Speech",
        "demoSrc": "-",
        "award": "ICHack 2022 - Runner Up",
        "article": false,
        "featured": false,
        "content": "..."
    },
    {
        "id": 13,
        "title": "FairLens",
        "imgSrc": "https://lh3.googleusercontent.com/pw/AM-JKLXNiy3vIF9zWBKAnNrxK8ZqPjDCSFSE9dBMQexGy7cdNnIrGmBOLN10OEIkhNYt9itVSdvRZow_XqrVCjXuKkwMSIzs9zx8Wxx2MP4ilvhEKimVnStCPzw6nKJB3IGXw9wI04MPcspw7_bWlpQfsEtqig=w1067-h600-no",
        "pretext": "An open source project built to assess fairness in machine learning models and in data.",
        "timespan": "6 months",
        "date": "2021-08-06",
        "teamSize": 0,
        "links": "{\"git\": \"https://github.com/synthesized-io/fairlens\"}",
        "technologies": "Python, PyData Stack",
        "keywords": "Fairness, Explainability, Machine Learning",
        "demoSrc": "-",
        "award": "-",
        "article": true,
        "featured": false,
        "content": "..."
    },
    {
        "id": 6,
        "title": "IC ARM Project",
        "imgSrc": "https://lh3.googleusercontent.com/pw/ACtC-3ezjy4A7F_wd8G8i4wi5ZVQCBuymaVKL0hNKr1OSaFPQh8Rg-mBh3PsBteqDHV47_9LocfX0zcjS3thlInGoZt_ohnT-nByo_jGscQAI6_tdkvTdVilU5zWYtyJpiR6iWW2WoPpTe7d1nE4aAHyYsJw=w1677-h943-no",
        "pretext": "An ARM11 emulator and assembler in C written for a school project. Extended the project to make an RPI OS kernel capable of rendering graphics with no external libraries, along with a lightweight MVC and graphics library, and an animated bare metal game with dynamic map generation.",
        "timespan": "4 weeks",
        "date": "2019-06-21",
        "teamSize": 4,
        "links": "{\"git\": \"https://github.com/Hilly12/arm11_02_2019\"}",
        "technologies": "C, ARM",
        "keywords": "MVC, Rasberry Pi, Bare Metal",
        "demoSrc": "-",
        "award": "-",
        "article": false,
        "featured": false,
        "content": "..."
    },
    {
        "id": 2,
        "title": "Roguelike",
        "imgSrc": "https://lh3.googleusercontent.com/pw/ACtC-3dnTCVhRSSZToV-QkoGjHXdj0xG8pWsHPJi_QSeCUt0ADoUh1I7XQNY4Uuk4ipbKS9n6BXKUFQwmZ6eeyFb-FOJ_DL2kUC1f60flBm36jfP5IuSSVDQFMoFX4RVpxt5Zpa2geBwlvLsYkdDJsX1tYfT=w320-h180-no",
        "pretext": "A Roguelike game consisting of a dynamically generated dungeon and over 50 different enemies with varying difficulty.",
        "timespan": "3 months",
        "date": "2017-12-06",
        "teamSize": 1,
        "links": "{\"git\": \"https://github.com/Hilly12/Java-Roguelike-A2-Project\"}",
        "technologies": "Java",
        "keywords": "Roguelike Game, Procedural Generation",
        "demoSrc": "-",
        "award": "-",
        "article": false,
        "featured": false,
        "content": "<html>\r\n<header>\r\nA Roguelike for Web Browsers\r\n</header>\r\n<body>\r\nHi\r\n</body>\r\n</html>"
    },
    {
        "id": 1,
        "title": "WACC Compiler",
        "imgSrc": "https://lh3.googleusercontent.com/pw/ACtC-3d876tfNuFY_bsQvrVpRMU3QcR7BvmCTU_XxD9TbJCc8t_dDLJ8YsD2siEkZ5szm6Ip_IyzwrsAKLQNfpuBmrbYBEgb4BbmaHNXtIH7WWXQEQ-ARbAX9t7NRIjDaTD45aCHHY6OEtCrIQTaQByH_TSV=w680-h383-no",
        "pretext": "A compiler for a while language, made in Java. Consisted of fast ssa and control flow optimisations including constant propagation and liveness analysis.",
        "timespan": "8 weeks",
        "date": "2020-03-13",
        "teamSize": 4,
        "links": "{}",
        "technologies": "Java, ARM",
        "keywords": "Compilers, Compiler Optimizations",
        "demoSrc": "-",
        "award": "-",
        "article": false,
        "featured": false,
        "content": "WACC..."
    },
    {
        "id": 11,
        "title": "Book Arbitrage Software",
        "imgSrc": "https://lh3.googleusercontent.com/pw/ACtC-3fUrCVjSyiagIVXDONxVGEHBvUPIpgjnQr1YtXU8rTXC1SPZxB3v42vG05AnJ0_iU2yxWPY8fcskVL7zF6JeBepQhaMnI4PehIzFXOZORhIHeNogfZkXPH5dTIhZkwLh3o_LRbOOkoLdGp3WCrq5qAs=w791-h445-no",
        "pretext": "A scalable, self-sustaining system for finding, tracking, and filtering across a large amount of book arbitrage opportunities scraped from multiple marketplaces.",
        "timespan": "12 weeks",
        "date": "2021-01-14",
        "teamSize": 7,
        "links": "{}",
        "technologies": "Python, Flask, MongoDB",
        "keywords": "Arbitrage, Scalability, Bayesian Inference",
        "demoSrc": "-",
        "award": "-",
        "article": false,
        "featured": false,
        "content": ""
    },
    {
        "id": 5,
        "title": "Treble",
        "imgSrc": "https://lh3.googleusercontent.com/pw/ACtC-3dywuSqmOL6IxBJTstZH6IQkN3AFsJFn8fQr8HaMCC9XmqaKNyywGbU6r-EW-y2ozLFuvX_Wl762TEkprVgo6TZt5dryQw5wwF7-yBA4kZGUoWZXinRFXsRRZDcXc7TRjeJKYKV1kkXX8qLnFUYr4b6=w1570-h883-no",
        "pretext": "An application for musicians to find gigs and mentor other musicians.",
        "timespan": "3 weeks",
        "date": "2020-06-16",
        "teamSize": 3,
        "links": "{\"git\": \"https://github.com/ais-creations/musicalmedium\"}",
        "technologies": "React, Node, Express, MongoDB",
        "keywords": "Full Stack, HCD",
        "demoSrc": "-",
        "award": "-",
        "article": false,
        "featured": false,
        "content": "..."
    },
    {
        "id": 3,
        "title": "Pintos",
        "imgSrc": "https://lh3.googleusercontent.com/pw/ACtC-3dA-iogWXl4OKrx-qA2d2WYLOWLXvVr0mFRQS0Aqdz0iRB7rhan0IRh0Q3hOFXpbeE1s2i_-OI4o4BBk6Qp3l3b-CD2pHCPzftipOY5TCGSjh1YEl5DSk9IBv2m5kXeeZ2DrZPIFshH2dB-1oGmcq_A=w731-h411-no",
        "pretext": "Extension of a monolithic operating system, Pintos, with functionality including a priority and BSD scheduler, user programs with 14 syscalls, and virtual memory with paging and swap.",
        "timespan": "9 weeks",
        "date": "2019-12-06",
        "teamSize": 4,
        "links": "{}",
        "technologies": "C",
        "keywords": "Operating Systems, Concurrency",
        "demoSrc": "-",
        "award": "-",
        "article": false,
        "featured": false,
        "content": "Pintos..."
    },
    {
        "id": 4,
        "title": "Porcupine Smart Mirror",
        "imgSrc": "https://lh3.googleusercontent.com/pw/ACtC-3evW9NbSKJd-dUUFWTRoh9amWCbc96SCHU0uk8dDgn1zDxFGxXdACSLbI_9sLij5v47IPjHZlcx9Fq3iCf3kKoB2pFiNYg-nX-MDezHgk8WSPZ14uVm9QtcRKD7qz7sy3KowG5RHzvJNHcd3oWC81LG=w776-h437-no",
        "pretext": "A Raspberry Pi powered smart mirror capable of detecting people and rating their outfits.",
        "timespan": "24 hours",
        "date": "2020-02-09",
        "teamSize": 5,
        "links": "{}",
        "technologies": "Python, Tensorflow",
        "keywords": "Deep Learning, Smart Mirror, Outfit Classifier",
        "demoSrc": "-",
        "award": "ICHack20 - Best IOT Hack",
        "article": false,
        "featured": false,
        "content": ""
    },
    {
        "id": 9,
        "title": "Singalong",
        "imgSrc": "https://lh3.googleusercontent.com/pw/ACtC-3fG6k-BqQ8Es2duedgL1BpJX7eVT-TGNfxEVy0zCOtik3cpHICbgXNmUn2G3ZtlMuHT6pNQL7f0tuIDP-ylAW380Hkk5bPyg8C7Q_evzjkDIuYVJ05ltWSAbIqrm254sPva40HjpiTY04XLEzYguJvu=w222-h125-no?authuser=0",
        "pretext": "Singalong can detect and play songs a user is singing from where they left off.",
        "timespan": "24 hours",
        "date": "2019-01-26",
        "teamSize": 5,
        "links": "{\"git\": \"https://github.com/hilly12/singalong\"}",
        "technologies": "Python, Java, Android",
        "keywords": "Text to Speech, App, Android",
        "demoSrc": "-",
        "award": "ICHack19 - Runner up",
        "article": false,
        "featured": false,
        "content": ""
    },
    {
        "id": 12,
        "title": "Stock Dashboard",
        "imgSrc": "https://lh3.googleusercontent.com/pw/ACtC-3cz2cDxxogDS0UDCnWD_rUsN4YPzlkp9RswX1bzliCqzaKz0WS9XZwvxQM4S5ztWpY22R7o42WWa6HMVVjVQKGTuZTDuTTdwR42FZ4yOBmElti7Ud0tu_ioKgvCBqh09SqQA7bQqwoIf4i65xTxe1hA=w720-h405",
        "pretext": "A dashboard for analyzing stocks from popular indexes such as S&P 500, FTSE 100, NIFTY 50, based on quantitative value indicators. Backed by an API serving financial data regularly extracted from Yahoo Finance.",
        "timespan": "3 weeks",
        "date": "2021-01-21",
        "teamSize": 1,
        "links": "{\"git\": \"https://github.com/Hilly12/stocks\"}",
        "technologies": "Python, Django, React",
        "keywords": "Dashboard, Finance, ORM",
        "demoSrc": "../sandbox/stocks",
        "award": "-",
        "article": false,
        "featured": false,
        "content": ""
    },
    {
        "id": 8,
        "title": "Alphazero Pawn Race",
        "imgSrc": "https://lh3.googleusercontent.com/pw/ACtC-3d7fd7hfXKe7Y3KvuMXCKbblWV_ced0futGrTMWS-NlJOFgUhFRDsuBeRcgcKLC4hpGLIpBCCRK5_uw6YamMUBDnU6pXlZVAauKYHxUnssfdi2UPu6ZDN0UMLWip1lZ5JaPRP9nyb6yKRAkVpJbbYHO=w1065-h599-no",
        "pretext": "An alphazero inspired chess engine for only pawns. Uses an agent with a policy value network and monte carlo tree search.",
        "timespan": "5 weeks",
        "date": "2020-10-10",
        "teamSize": 1,
        "links": "{\"git\": \"https://github.com/Hilly12/alphazero-pawnrace\"}",
        "technologies": "Python, Tensorflow, Keras",
        "keywords": "Policy-Value Network, Reinforcement Learning, Monte Carlo Tree Search",
        "demoSrc": "-",
        "award": "-",
        "article": false,
        "featured": false,
        "content": "<html>\r\n<header>\r\nCreating an Alphazero inspired Chess Engine for Pawns\r\n</header>\r\n<body>\r\n<p><b>This post has not yet been written since the project is a WIP, but it's here to demonstrate the blog feature</b></p>\r\nLorem <a href=\"//www.aahilm.com\">ipsum</a> dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\r\n<img src=\"https://lh3.googleusercontent.com/pw/ACtC-3d7fd7hfXKe7Y3KvuMXCKbblWV_ced0futGrTMWS-NlJOFgUhFRDsuBeRcgcKLC4hpGLIpBCCRK5_uw6YamMUBDnU6pXlZVAauKYHxUnssfdi2UPu6ZDN0UMLWip1lZ5JaPRP9nyb6yKRAkVpJbbYHO=w1065-h599-no\"/>\r\nSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?\r\n</body>\r\n</html>"
    },
    {
        "id": 10,
        "title": "Java Pawn Race",
        "imgSrc": "https://lh3.googleusercontent.com/pw/ACtC-3cJyNxcpeGbeD3q-GnmhoWRIpRGgLKypkTf9GKVhFuCRhOMJ_rZpV8hl-Pf4k9scqeV7qoyiSYPfmqHG3__g0zsGOHcPeTJFP3-Sfk-arKNfXKAqVP7PBI8u3RawDgBII9iSPmtDtCnqI0v47bTu9KB=w1605-h903-no",
        "pretext": "A pawn-only chess engine using Bitboards, Zobrist hashing, Transposition Tables, Iterative Deepening, and Alpha Beta Search, made in Java.",
        "timespan": "1 week",
        "date": "2018-12-12",
        "teamSize": 1,
        "links": "{\"git\": \"https://github.com/Hilly12/Java-Pawn-Race\"}",
        "technologies": "Java",
        "keywords": "Minimax, Hashing, Bit Manipulation",
        "demoSrc": "../sandbox/chess",
        "award": "2nd in Imperial Java Pawn Race",
        "article": false,
        "featured": false,
        "content": ""
    }
];

export default Projects;